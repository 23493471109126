<template>
  <v-row data-testid="basal-once-weekly-section">
    <v-col cols="12" sm="3" lg="3">
      <ReviewField
        :is-dose-value="true"
        :display-value="prescription.basal_once_weekly.value"
        :dosing-display-unit="dosingDisplayUnit('basal_once_weekly')"
        data-testid="basal-once-weekly-value"
      >
        Initial <strong>weekly</strong> dose
      </ReviewField>
    </v-col>
    <v-col sm="5">
      <ReviewField
        :display-value="dosingDayDisplayName"
        data-testid="basal-once-weekly-dosing-day"
      >
        Select a <strong>weekly</strong> dosing day for the patient
      </ReviewField>
    </v-col>
    <v-col
      cols="12"
      sm="3"
      lg="3"
      data-testid="basal-once-daily-derived-section"
    >
      <ReviewField
        :is-dose-value="true"
        :display-value="prescription.basal_once_daily.value"
        :dosing-display-unit="dosingDisplayUnit('basal_once_daily')"
        :is-derived="true"
        :trial-site-no="siteNo"
        data-testid="basal-once-daily-derived-value"
      >
        Initial <strong>daily</strong> dose
      </ReviewField>
    </v-col>
  </v-row>
</template>

<script>
import initialPrescriptionMixin from './initialPrescriptionMixin'
import ReviewField from '../shared/ReviewField'

export default {
  name: 'DoubleDummyOverview',
  mixins: [initialPrescriptionMixin],
  components: { ReviewField },
  props: {
    siteNo: { type: String, required: true },
  },
  computed: {
    dosingDayDisplayName() {
      if (!this.prescription.dosingDay) return ''

      return this.dosingDayOptions.find(
        dosingDayOption => dosingDayOption.value === this.prescription.dosingDay
      )?.text
    },
  },
}
</script>

<style scoped></style>
